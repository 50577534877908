@mixin font-face($font-family, $file-path, $weight: normal, $style: normal, $format: 'woff') {
  @font-face {
    font-family: $font-family;
    font-weight: $weight;
    font-style: $style;
    font-display: swap;

    @if $format=='woff' {
      src: url('#{$file-path}.#{$format}') format($format);
    }

    @else {
      src: url('#{$file-path}.#{$format}') format('truetype');
    }
  }
}

@include font-face('Inter', '../../shared/assets/fonts/Inter-Regular', 400, normal, 'ttf');
@include font-face('Inter', '../../shared/assets/fonts/Inter-Medium', 500, medium, 'ttf');
@include font-face('Poppins', '../../shared/assets/fonts/Poppins-Light', 300, light, 'ttf');
@include font-face('Poppins', '../../shared/assets/fonts/Poppins-Regular', 400, normal, 'ttf');
@include font-face('Poppins', '../../shared/assets/fonts/Poppins-Medium', 500, medium, 'ttf');
@include font-face('Poppins', '../../shared/assets/fonts/Poppins-Bold', 700, bold, 'ttf');
@include font-face('PP Editoral', '../../shared/assets/fonts/PPEditorialNew-Italic', 400, italic, 'otf');